import React, { FunctionComponent, useEffect } from 'react'
import { Grid, Heading, Spinner } from '@chakra-ui/react'

const AuthCompletePage: FunctionComponent = () => {
  useEffect(() => {
    window.top?.postMessage('3DS-authentication-complete')
  }, [])

  return (
    <Grid gap="2" p="2">
      <Heading>{`Authentication complete...`}</Heading>
      <Spinner thickness="4px" speed="0.65s" color="msuGreen.500" size="xl" />
    </Grid>
  )
}

export default AuthCompletePage
